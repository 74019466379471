import { NavLink, Redirect } from "react-router-dom";
import { addGenre, fetchGenre, updateGenre } from "../../actions/genres";

import { Dropdown } from "semantic-ui-react";
import PropTypes from "prop-types";
import React from "react";
import { SketchPicker } from "react-color";
import SongSearch from "../Song/SongSearch";
import classnames from "classnames";
import { connect } from "react-redux";
import { fetchSongs } from "../../actions/songs";
import reactCSS from "reactcss";

/**
 * @description Genre form used to add and edit. We bind state and events binding to the component by constructor this time.
 * @extends React
 */
class GenreForm extends React.Component {
  file;

  constructor(props) {
    super(props);

    this.state = {
      id: "",
      title: "",
      color: "",
      songs: [],
      allSongs: [],
      errors: {},
      loading: false,
      redirect: false,
      displayColorPicker: false,
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  componentDidMount() {
    this.props.fetchSongs();
    if (
      this.props.match &&
      this.props.match.params &&
      typeof this.props.match.params.id !== "undefined" &&
      this.props.match.params.id !== "new"
    ) {
      this.props.fetchGenre(this.props.match.params.id);
    }
    // this.setState({
    // 	id: (this.props.genre) ? this.props.genre.id : '',
    // 	title: (this.props.genre) ? this.props.genre.title : '',
    // });
  }

  componentWillReceiveProps = (nextProps) => {
    if (nextProps.genre) {
      this.setState({
        id: nextProps.genre.id,
        title: nextProps.genre.title,
        color: nextProps.genre.color,
        songs: nextProps.genre.songs,
        allSongs: nextProps.allSongs,
      });
    }
  };

  handleChange(e) {
    this.setState({ [e.target.name]: e.target.value });
  }

  handleDropdownChange = (e, { value }) => {
    this.setState({
      songs: this.state.allSongs.filter((x) => value.indexOf("" + x.id) >= 0),
    });
  };

  handleSubmit(e) {
    e.preventDefault();
    let errors = {};

    // Validation
    if (this.state.title === "") errors.title = "This field can't be empty";

    // Fill the errors object state
    this.setState({ errors });

    // Proceed if everything is OK
    if (Object.keys(errors).length === 0) {
      const { id, title, songs, color } = this.state;
      this.setState({ loading: true });
      //this.props.saveGenre({ id, title });

      if (!id)
        this.props
          .addGenre({ title, color })
          .then(() => this.setState({ redirect: true }));
      else
        this.props
          .updateGenre({ id, title, songs, color })
          .then(() => this.setState({ redirect: true }));
    }
  }

  onFileChange = (e, data) => {
    if (e.target.files.length <= 0) return;
    console.log("onFileChange", e.target.files[0]);
    this.file = e.target.files[0];
  };

  handleColorPickerClick = () => {
    this.setState({ displayColorPicker: !this.state.displayColorPicker });
  };

  handleColorPickerClose = () => {
    this.setState({ displayColorPicker: false });
  };

  handleColorPickerChange = (color) => {
    if (color) {
      this.setState({ color: color.hex });
    }
  };

  render() {
    const styles = reactCSS({
      default: {
        color: {
          width: "36px",
          height: "14px",
          borderRadius: "2px",
          background: this.state.color,
        },
        swatch: {
          padding: "5px",
          background: "#fff",
          borderRadius: "1px",
          boxShadow: "0 0 0 1px rgba(0,0,0,.1)",
          display: "inline-block",
          cursor: "pointer",
        },
        popover: {
          position: "absolute",
          zIndex: "2",
        },
        cover: {
          position: "fixed",
          top: "0px",
          right: "0px",
          bottom: "0px",
          left: "0px",
        },
      },
    });

    return (
      <div>
        {// Redirect if some action has worked succesfully, render if not
        this.state.redirect ? (
          <Redirect to="/genres" />
        ) : (
          <div className="ui container">
            <h1>Uređivanje žanra</h1>
            <NavLink exact to="/genres/" className="ui button">
              Nazad na listu žanrova
            </NavLink>
            <br />
            <br />
            <form
              className={classnames("ui", "form", {
                loading: this.state.loading,
              })}
              onSubmit={this.handleSubmit}
            >
              <h4 className="ui dividing header">
                Popunite formu ispod sa informacijama o žanru
              </h4>

              {!!this.state.errors.global && (
                <div className="ui negative message">
                  <p>{this.state.errors.global}</p>
                </div>
              )}

              <div
                className={classnames("field", {
                  error: !!this.state.errors.title,
                })}
              >
                <label htmlFor="title">Naziv</label>
                <input
                  type="text"
                  id="title"
                  name="title"
                  value={this.state.title}
                  className="ui input"
                  placeholder="Naziv žanra"
                  onChange={this.handleChange}
                />
                <span>{this.state.errors.title}</span>
              </div>

              <div>
                <label htmlFor="color">Boja</label>
                <div>
                  <div
                    style={styles.swatch}
                    onClick={this.handleColorPickerClick}
                  >
                    <div style={styles.color} />
                  </div>
                  {this.state.displayColorPicker ? (
                    <div style={styles.popover}>
                      <div
                        style={styles.cover}
                        onClick={this.handleColorPickerClose}
                      />
                      <SketchPicker
                        color={{ hex: this.state.color }}
                        onChange={this.handleColorPickerChange}
                      />
                    </div>
                  ) : null}
                </div>
                {/* <input
                  type="text"
                  id="color"
                  name="color"
                  value={this.state.color}
                  className="ui input"
                  placeholder="Boja žanra"
                  onChange={this.handleChange}
                /> */}
              </div>
              <br />

              <label>Dodaj pjesmu u žanr</label>
              <SongSearch />
              <div className="field">
                <br />
                <button type="submit" className="ui primary button">
                  Snimi
                </button>
              </div>
            </form>
          </div>
        )}
      </div>
    );
  }
}

GenreForm.propTypes = {
  genre: PropTypes.object,
  fetchGenre: PropTypes.func.isRequired,
  updateGenre: PropTypes.func.isRequired,
  addGenre: PropTypes.func.isRequired,
  fetchSongs: PropTypes.func.isRequired,
};

function mapStateToProps(state, props) {
  if (props.match && props.match.params && props.match.params.id > 0) {
    return {
      genre: state.genres.find((item) => item.id == props.match.params.id),
      allSongs: state.songs,
    };
  }

  return { genre: null, allSongs: null };
}

export default connect(
  mapStateToProps,
  {
    fetchGenre,
    updateGenre,
    addGenre,
    fetchSongs,
  }
)(GenreForm);

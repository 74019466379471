import React from "react";
import { connect } from "react-redux";
import { fetchDefaultRepertoire } from "../actions/repertoires";
import { withRouter } from "react-router-dom";

class Footer extends React.Component {
  redirectToCurrent = () => {
    this.props.fetchDefaultRepertoire().then((repertoire) => {
      var needsReload =
        window.location.pathname.startsWith(`/repertoire/`) &&
        !window.location.pathname.startsWith(`/repertoire/${repertoire.id}`);
      this.props.history.push(`/repertoire/${repertoire.id}`);
      if (needsReload) {
        new CustomEvent("socket_connect", { detail: repertoire });
      }
    });
  };

  render() {
    const { user: currentUser } = this.props;

    return (
      <div className="ui fixed inverted footer menu">
        {currentUser && (
          <button
            className="item"
            activeonlywhenexact="active"
            exact
            onClick={this.redirectToCurrent}
          >
            Trenutna pjesma
          </button>
        )}
      </div>
    );
  }
}

function mapStateToProps(state) {
  const { user } = state.auth;
  return {
    user,
  };
}

export default withRouter(
  connect(
    mapStateToProps,
    { fetchDefaultRepertoire }
  )(Footer)
);

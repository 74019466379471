import { NavLink, Redirect } from "react-router-dom";

import PropTypes from "prop-types";
import React from "react";
import classnames from "classnames";
import { connect } from "react-redux";
import { deleteRepertoire } from "../../actions/repertoires";

/**
 * @description Repertoire form used to add and edit. We bind state and events binding to the component by constructor this time.
 * @extends React
 */
class RepertoireDelete extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      id: "",
      title: "",
      errors: {},
      loading: false,
      redirect: false,
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  componentDidMount() {
    if (
      this.props.match &&
      this.props.match.params &&
      typeof this.props.match.params.id !== "undefined" &&
      this.props.match.params.id !== "new"
    ) {
      // this.props.fetchRepertoire(this.props.match.params.id);
    }
    this.setState({
      id: this.props.repertoire ? this.props.repertoire.id : "",
      title: this.props.repertoire ? this.props.repertoire.title : "",
    });
  }

  componentWillReceiveProps = (nextProps) => {
    this.setState({
      id: nextProps.repertoire.id,
    });
  };

  handleChange(e) {
    this.setState({ [e.target.name]: e.target.value });
  }

  handleSubmit(e) {
    console.log("Delete handleSubmit", e);
    e.preventDefault();
    let errors = {};

    // Fill the errors object state
    this.setState({ errors });

    // Proceed if everything is OK
    if (Object.keys(errors).length === 0) {
      let id = this.state.id || this.props.match.params.id || "";
      if (id) {
        this.setState({ loading: true });
        //this.props.saveRepertoire({ id, title });
        // console.log(this.state);

        this.props
          .deleteRepertoire({ id })
          .then(() => this.setState({ redirect: true }));
      }
    }
  }

  render() {
    return (
      <div>
        {// Redirect if some action has worked succesfully, render if not
        this.state.redirect ? (
          <Redirect to="/repertoires" />
        ) : (
          <div className="ui container">
            <h1>Brisanje repertoara: {this.state.title}</h1>
            <NavLink exact to="/repertoires/" className="ui button">
              Nazad na listu repertoara
            </NavLink>
            <br />
            <br />
            <form
              className={classnames("ui", "form", {
                loading: this.state.loading,
              })}
              onSubmit={this.handleSubmit}
            >
              <h4 className="ui dividing header">
                Da li ste sigurni da želite da trajno obrišete repertoar?
              </h4>

              {!!this.state.errors.global && (
                <div className="ui negative message">
                  <p>{this.state.errors.global}</p>
                </div>
              )}

              <div className="field">
                <button type="submit" className="ui primary button">
                  Obriši
                </button>
              </div>
            </form>
          </div>
        )}
      </div>
    );
  }
}

RepertoireDelete.propTypes = {
  repertoire: PropTypes.object,
  deleteRepertoire: PropTypes.func.isRequired,
};

function mapStateToProps(state, props) {
  if (props.match && props.match.params && props.match.params.id > 0) {
    return {
      repertoire: state.repertoires.find(
        (item) => item.id == props.match.params.id
      ),
    };
  }

  return { repertoire: null };
}

export default connect(
  mapStateToProps,
  { deleteRepertoire }
)(RepertoireDelete);

import { NavLink, withRouter } from "react-router-dom";

import PropTypes from "prop-types";
import React from "react";
import classnames from "classnames";
import { connect } from "react-redux";
// Actions
import { fetchSongs } from "../../actions/songs";

class SongsListPage extends React.Component {
  /**
   * Fetch all songs on component load
   */
  componentDidMount() {
    this.props.fetchSongs();
  }

  openSong = (id) => {
    return () => {
      return this.props.history.push(`/song/${id}`);
    };
  };

  render() {
    let gridLines = "";

    const songs = this.props.songs;

    if (songs && songs.length !== 0) {
      gridLines = songs.map((song, i) => {
        if (song) {
          return (
            <tr key={song.id}>
              <td onClick={this.openSong(song.id)}>{song.id}</td>
              <td onClick={this.openSong(song.id)}>{song.title}</td>
              <td onClick={this.openSong(song.id)}>{song.singer}</td>
              <td onClick={this.openSong(song.id)}>{song.tonality}</td>
              <td style={{ textAlign: "right" }}>
                <NavLink
                  className="ui button compact icon small black"
                  to={`/song/${song.id}`}
                  title="More details"
                >
                  <i className="icon eye" />
                </NavLink>
                <NavLink
                  className="ui button compact icon small blue"
                  to={`/song/${song.id}/edit`}
                  title="Edit"
                >
                  <i className="icon edit" />
                </NavLink>
                <NavLink
                  className="ui button compact icon small red"
                  to={`/song/${song.id}/delete`}
                  title="Delete"
                >
                  <i className="icon trash" />
                </NavLink>
              </td>
            </tr>
          );
        }
      });
    }

    const grid = (
      <table className="ui striped selectable compact table single line">
        <thead>
          <tr>
            <th width="7%">ID</th>
            <th>Naziv</th>
            <th>Pjevač</th>
            <th>Tonalitet</th>
            <th width="15%" />
          </tr>
        </thead>
        <tbody>{gridLines}</tbody>
      </table>
    );

    const emptyMessage = (
      <div className="ui info message">Trenutno nema pjesama</div>
    );

    return (
      <div className="ui container">
        <h1>Pjesme</h1>
        <NavLink exact to="/song/new" className="ui button primary">
          Dodaj
        </NavLink>
        {songs && songs.length > 0 ? grid : emptyMessage}
      </div>
    );
  }
}

SongsListPage.propTypes = {
  songs: PropTypes.array.isRequired,
  fetchSongs: PropTypes.func.isRequired,
};

function mapStateToProps(state) {
  return {
    songs: state.songs,
  };
}

export default withRouter(
  connect(
    mapStateToProps,
    { fetchSongs }
  )(SongsListPage)
);

import authHeader from "../utils/auth-header";
import axios from "axios";

// -----------------------------------------------------------
// Action types
// -----------------------------------------------------------
export const SET_REPERTOIRES = "SET_REPERTOIRES";
export const REPERTOIRE_FETCHED = "REPERTOIRE_FETCHED";
export const REPERTOIRE_SAVED = "REPERTOIRE_SAVED";
export const REPERTOIRE_UPDATED = "REPERTOIRE_UPDATED";
export const REPERTOIRE_DELETED = "REPERTOIRE_DELETED";
export const SET_REPERTOIRE_ALBUMS = "SET_REPERTOIRE_ALBUMS";
export const REPERTOIRE_SONGS_FETCHED = "REPERTOIRE_SONGS_FETCHED";

// -----------------------------------------------------------
// Actions to be dispatched to the reducer
// -----------------------------------------------------------
export function setRepertoires(repertoires) {
  return { type: SET_REPERTOIRES, repertoires };
}
export function repertoireFetched(repertoire) {
  return { type: REPERTOIRE_FETCHED, repertoire };
}
export function repertoireSongsFound(repertoireSearchedSongs) {
  return { type: REPERTOIRE_SONGS_FETCHED, repertoireSearchedSongs };
}
export function repertoireSaved(repertoire) {
  // repertoire.songs = []; // Initialize with empty albums list
  return { type: REPERTOIRE_SAVED, repertoire };
}
export function repertoireUpdated(repertoire) {
  return { type: REPERTOIRE_UPDATED, repertoire };
}
export function repertoireDeleted(repertoireId) {
  return { type: REPERTOIRE_DELETED, repertoireId };
}
export function setRepertoireAlbums(repertoireId, albums) {
  return { type: SET_REPERTOIRE_ALBUMS, repertoireId, albums };
}

// -----------------------------------------------------------
// Business rules before dispatch
// -----------------------------------------------------------
export function fetchRepertoires() {
  return (dispatch) => {
    return axios
      .get("/api/repertoire", { headers: authHeader() })
      .then((response) => dispatch(setRepertoires(response.data.repertoires)))
      .catch((error) => console.log(error));
  };
}
export function fetchRepertoire(id) {
  return (dispatch) => {
    return axios
      .get(`/api/repertoire/${id}`, { headers: authHeader() })
      .then((response) => dispatch(repertoireFetched(response.data.repertoire)))
      .catch((error) => console.log(error));
  };
}
export function fetchDefaultRepertoire() {
  return (dispatch) => {
    return axios
      .get(`/api/repertoire/default`, { headers: authHeader() })
      .then((response) => response.data.repertoire)
      .catch((error) => console.log(error));
  };
}
export function searchRepertoireSong(query) {
  return (dispatch) => {
    return axios
      .post(`/api/repertoire/find-songs`, { query }, { headers: authHeader() })
      .then((response) => dispatch(repertoireSongsFound(response.data.songs)))
      .catch((error) => console.log(error));
  };
}
export function addRepertoire(repertoire) {
  return (dispatch) => {
    let { title } = repertoire;
    return axios
      .post("/api/repertoire", { title }, { headers: authHeader() })
      .then((response) => dispatch(repertoireSaved(response.data.repertoire)))
      .catch((error) => console.log(error));
  };
}
export function updateRepertoire(repertoire) {
  return (dispatch) => {
    let { id, title, songs } = repertoire;
    return axios
      .put(
        `/api/repertoire/${id}`,
        { id, title, songs },
        { headers: authHeader() }
      )
      .then((response) => dispatch(repertoireUpdated(response.data.repertoire)))
      .catch((error) => console.log(error));
  };
}
export function importRepertoire(repertoire, file) {
  return (dispatch) => {
    const formData = new FormData();
    let { id, title, songs } = repertoire;
    formData.append("id", id);
    formData.append("title", title);
    formData.append("songs", JSON.stringify(songs));
    formData.append("file", file, file.name);
    return axios
      .post("/api/repertoire/csv", formData, { headers: authHeader() })
      .then((response) => {
        if (repertoire.id) {
          return dispatch(repertoireSaved(response.data.repertoire));
        } else {
          return dispatch(repertoireUpdated(response.data.repertoire));
        }
      })
      .catch((error) => console.log(error));
  };
}
export function deleteRepertoire(repertoire) {
  return (dispatch) => {
    return axios
      .delete(`/api/repertoire/${repertoire.id}`, { headers: authHeader() })
      .then((response) => dispatch(repertoireDeleted(response.data.repertoire)))
      .catch((error) => console.log(error));
  };
}
export function setSongToCurrent(repertoire, song) {
  let { id: repertoireId } = repertoire;
  let { id: songId } = song;
  return (dispatch) => {
    return axios
      .post(
        `/api/repertoire/${repertoireId}/song/${songId}/current`,
        {},
        {
          headers: authHeader(),
        }
      )
      .then((response) => dispatch(repertoireSaved(response.data.repertoire)))
      .catch((error) => console.log(error));
  };
}

export function removeSong(repertoire, song) {
  let { id: repertoireId } = repertoire;
  let { id: songId } = song;
  return (dispatch) => {
    return axios
      .delete(`/api/repertoire/${repertoireId}/song/${songId}`, {
        headers: authHeader(),
      })
      .then((response) => response.data.repertoire)
      .catch((error) => console.log(error));
  };
}

export function moveSongUp(repertoire, song) {
  let { id: repertoireId } = repertoire;
  let { id: songId } = song;
  return (dispatch) => {
    return axios
      .post(
        `/api/repertoire/${repertoireId}/song/${songId}/up`,
        {},
        {
          headers: authHeader(),
        }
      )
      .then((response) => response.data.repertoire)
      .catch((error) => console.log(error));
  };
}

export function moveSongDown(repertoire, song) {
  let { id: repertoireId } = repertoire;
  let { id: songId } = song;
  return (dispatch) => {
    return axios
      .post(
        `/api/repertoire/${repertoireId}/song/${songId}/down`,
        {},
        {
          headers: authHeader(),
        }
      )
      .then((response) => response.data.repertoire)
      .catch((error) => console.log(error));
  };
}

import {
  addSong,
  fetchSong,
  getSongText,
  updateSong,
} from "../../actions/songs";

import { Dropdown } from "semantic-ui-react";
import PropTypes from "prop-types";
import React from "react";
import classnames from "classnames";
import { connect } from "react-redux";
import { fetchGenres } from "../../actions/genres";
import { fetchRepertoires } from "../../actions/repertoires";
import { withRouter } from "react-router-dom";

const queryStringTranslationMap = {
  genre: "žanr",
  repertoire: "repertoar",
};

/**
 * @description Song form used to add and edit. We bind state and events binding to the component by constructor this time.
 * @extends React
 */
class SongForm extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      id: "",
      title: "",
      singer: "",
      tonality: "",
      text: "",
      repertoires: [],
      allRepertoires: [],
      genres: [],
      allGenres: [],
      errors: {},
      loading: false,
      addToCurrentRepertoire: false,
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleDropdownChange = this.handleDropdownChange.bind(this);
    this.subscribeToRepertoireSongCurrentChanged();
  }

  componentDidMount = () => {
    this.props.fetchRepertoires();
    this.props.fetchGenres();
    if (
      this.props.match &&
      this.props.match.params &&
      typeof this.props.match.params.id !== "undefined" &&
      this.props.match.params.id !== "new"
    ) {
      this.props.fetchSong(this.props.match.params.id || "");
    }
    if (this.props.song && this.props.song.url) {
    }
    this.addQueryStringToState();
  };

  componentWillUnmount() {
    this.unsubscribeFromRepertoireSongCurrentChanged();
  }

  componentWillReceiveProps = (nextProps) => {
    if (nextProps.song) {
      this.setState({
        id: nextProps.song.id,
        title: nextProps.song.title,
        singer: nextProps.song.singer,
        tonality: nextProps.song.tonality,
        text: nextProps.song.text,
        repertoires: nextProps.song.repertoires || [],
        allRepertoires: nextProps.allRepertoires,
        genres: nextProps.song.genres || [],
        allGenres: nextProps.allGenres,
        addToCurrentRepertoire: false,
      });
      if (nextProps.song.url) {
        this.props
          .getSongText(nextProps.song.url)
          .then((text) => this.setState({ text }));
      }
    }
    this.addQueryStringToState();
  };

  onRepertoireSongCurrentChanged = (e) => {
    console.log("onRepertoireSongCurrentChanged", e);
    var repertoireId = e.detail.repertoire_id;
    if (repertoireId) {
      this.props.history.push(`/repertoire/${repertoireId}`);
      return;
    }
    var genreId = e.detail.genre_id;
    if (genreId) {
      this.props.history.push(`/genre/${genreId}`);
      return;
    }
  };

  subscribeToRepertoireSongCurrentChanged = () => {
    console.log("subscribeToRepertoireSongCurrentChanged");
    document.addEventListener(
      "current_song_changed",
      this.onRepertoireSongCurrentChanged
    );
  };

  unsubscribeFromRepertoireSongCurrentChanged = () => {
    console.log("unsubscribeFromRepertoireSongCurrentChanged");
    document.removeEventListener(
      "current_song_changed",
      this.onRepertoireSongCurrentChanged
    );
  };

  addQueryStringToState = () => {
    let query = new URLSearchParams(this.props.location.search);
    for (let i of Object.keys(queryStringTranslationMap)) {
      let value = query.get(i);
      this.setState({ [i]: value });
    }
  };

  handleChange(e) {
    this.setState({ [e.target.name]: e.target.value });
  }

  handleDropdownChange = (list, stateField) => {
    return (e, { value }) => {
      this.setState({
        [stateField]: list.filter((x) => value.indexOf("" + x.id) >= 0),
      });
    };
  };

  handleSubmit(key, value) {
    // e.preventDefault();
    let errors = {};

    // Validation
    if (this.state.title === "") errors.title = "This field can't be empty";
    if (this.state.singer === "") errors.singer = "This field can't be empty";

    // Fill the errors object state
    this.setState({ errors });

    // Proceed if everything is OK
    if (Object.keys(errors).length === 0) {
      const {
        id,
        title,
        singer,
        text,
        repertoires,
        genres,
        tonality,
      } = this.state;
      this.setState({ loading: true });

      if (!id || id === "new")
        this.props
          .addSong({ title, singer, text, tonality, [key]: value, genres })
          .then(() => this.props.history.goBack());
      else
        this.props
          .updateSong({
            id,
            title,
            singer,
            text,
            // repertoires,
            tonality,
            [key]: value,
            genres,
          })
          .then(() => this.props.history.goBack());
    }
  }

  render() {
    return (
      <div>
        <div className="ui container">
          <h1>Uređivanje pjesme</h1>
          <button
            onClick={() => this.props.history.goBack()}
            className="ui button"
          >
            Nazad
          </button>
          <br />
          <br />
          <form
            className={classnames("ui", "form", {
              loading: this.state.loading,
            })}
            onSubmit={(e) => e.preventDefault()}
          >
            <h4 className="ui dividing header">
              Popunite formu ispod sa informacijama o pjesmi
            </h4>

            {!!this.state.errors.global && (
              <div className="ui negative message">
                <p>{this.state.errors.global}</p>
              </div>
            )}

            <div
              className={classnames("field", {
                error: !!this.state.errors.title,
              })}
            >
              <label htmlFor="title">Naziv</label>
              <input
                type="text"
                id="title"
                name="title"
                value={this.state.title}
                className="ui input"
                placeholder="Naziv pjesme"
                onChange={this.handleChange}
              />
              <span>{this.state.errors.title}</span>
            </div>

            <div
              className={classnames("field", {
                error: !!this.state.errors.singer,
              })}
            >
              <label htmlFor="singer">Pjevač</label>
              <input
                type="text"
                id="singer"
                name="singer"
                value={this.state.singer}
                className="ui input"
                placeholder="Pjevač"
                onChange={this.handleChange}
              />
              <span>{this.state.errors.singer}</span>
            </div>
            <div
              className={classnames("field", {
                error: !!this.state.errors.tonality,
              })}
            >
              <label htmlFor="tonality">Tonalitet</label>
              <input
                type="text"
                id="tonality"
                name="tonality"
                value={this.state.tonality}
                className="ui input"
                placeholder="Tonalitet"
                onChange={this.handleChange}
              />
              <span>{this.state.errors.tonality}</span>
            </div>
            <div
              className={classnames("field", {
                error: !!this.state.errors.text,
              })}
            >
              <label htmlFor="description">Tekst</label>
              <textarea
                id="text"
                name="text"
                className="ui input"
                placeholder="Tekst pjesme"
                onChange={this.handleChange}
                value={this.state.text}
                style={{ height: "715px" }}
              />
              <span>{this.state.errors.text}</span>
            </div>
            {/* <div className="field">
                <input
                  value="Učitaj tekst"
                  type="button"
                  className="ui button"
                  onClick={this.loadText}
                />
              </div> */}
            {/* {this.state.allRepertoires &&
                this.state.allRepertoires.length > 0 &&
                this.state.repertoires && (
                  <Dropdown
                    placeholder="Repertoari"
                    fluid
                    multiple
                    selection
                    options={this.state.allRepertoires.map((x) => {
                      return (
                        x && {
                          key: "" + x.id,
                          text: x.title,
                          value: "" + x.id,
                        }
                      );
                    })}
                    value={this.state.repertoires.map((x) => "" + x.id)}
                    onChange={this.handleDropdownChange(this.state.allRepertoires, "repertoires")}
                  />
                )} */}
            {!Object.keys(queryStringTranslationMap).some(
              (x) => !!this.state[x]
            ) &&
              this.state.allGenres &&
              this.state.allGenres.length > 0 && (
                <div>
                  <Dropdown
                    placeholder="Žanrovi"
                    fluid
                    multiple
                    selection
                    options={this.state.allGenres.map((x) => {
                      return (
                        x && {
                          key: "" + x.id,
                          text: x.title,
                          value: "" + x.id,
                        }
                      );
                    })}
                    value={this.state.genres.map((x) => "" + x.id)}
                    onChange={this.handleDropdownChange(
                      this.state.allGenres,
                      "genres"
                    )}
                  />
                </div>
              )}
            <div className="field">
              <button
                type="submit"
                className="ui primary button"
                onClick={() => this.handleSubmit()}
              >
                Snimi
              </button>
              {Object.keys(queryStringTranslationMap).map((x) => {
                return (
                  !!this.state[x] && (
                    <button
                      className="ui secondary button"
                      onClick={() => this.handleSubmit(x, this.state[x])}
                    >
                      Snimi i dodaj u {queryStringTranslationMap[x]}
                    </button>
                  )
                );
              })}
            </div>
          </form>
        </div>
      </div>
    );
  }
}

SongForm.propTypes = {
  song: PropTypes.object,
  fetchSong: PropTypes.func.isRequired,
  updateSong: PropTypes.func.isRequired,
  addSong: PropTypes.func.isRequired,
  getSongText: PropTypes.func.isRequired,
  fetchRepertoires: PropTypes.func.isRequired,
  fetchGenres: PropTypes.func.isRequired,
};

function mapStateToProps(state, props) {
  if (props.match && props.match.params && props.match.params.id > 0) {
    return {
      song: state.songs.find((item) => item.id == props.match.params.id),
      allRepertoires: state.repertoires,
      allGenres: state.genres,
    };
  }
  const newSong = state.songEdit;
  if (newSong) {
    return {
      song: newSong,
      allRepertoires: state.repertoires,
      allGenres: state.genres,
    };
  }

  return { song: null, allRepertoires: null, allGenres: null };
}

export default withRouter(
  connect(
    mapStateToProps,
    {
      fetchSong,
      updateSong,
      addSong,
      getSongText,
      fetchRepertoires,
      fetchGenres,
    }
  )(SongForm)
);

import React from "react";
import { Redirect } from "react-router-dom";
import { connect } from "react-redux";

const parseJwt = (token) => {
  try {
    return JSON.parse(atob(token.split(".")[1]));
  } catch (e) {
    return null;
  }
};

const authComponent = (WrappedComponent) => {
  return class extends React.Component {
    render() {
      const currentUser = JSON.parse(localStorage.getItem("user"));

      if (!currentUser) {
        return <Redirect to="/login" />;
      } else {
        const decodedJwt = parseJwt(currentUser.token);
        if (decodedJwt.exp * 1000 < Date.now()) {
          localStorage.removeItem("user");
          return <Redirect to="/login" />;
        }
      }

      return <WrappedComponent {...this.props} />;
    }
  };
};

export default authComponent;

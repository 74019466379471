import {
  REPERTOIRE_DELETED,
  REPERTOIRE_FETCHED,
  REPERTOIRE_SAVED,
  REPERTOIRE_UPDATED,
  SET_REPERTOIRES,
  SET_REPERTOIRE_ALBUMS,
} from "../actions/repertoires";

import { sortArrayByField } from "../utils/common";

export default function repertoires(state = [], action = {}) {
  switch (action.type) {
    // ---------------------------------------------------------
    // Repertoire
    // ---------------------------------------------------------
    case SET_REPERTOIRES:
      return action.repertoires;
    case REPERTOIRE_FETCHED:
      if (state.findIndex((item) => item.id == action.repertoire.id) > -1) {
        return state.map((item) => {
          if (
            item &&
            action &&
            action.repertoire &&
            item.id == action.repertoire.id
          )
            return action.repertoire;
          return item;
        });
      } else return [...state, action.repertoire];
    case REPERTOIRE_SAVED:
      return [...state, action.repertoire];
    case REPERTOIRE_UPDATED:
      return state.map((item) => {
        if (item.id == action.repertoire.id) return action.repertoire;
        return item;
      });
    case REPERTOIRE_DELETED:
      return state.filter((item) => item.id != action.repertoireId);
    case SET_REPERTOIRE_ALBUMS:
      return state.map((item) => {
        item.albums = [];
        if (item.id == action.repertoireId) item.albums = action.albums;
        return item;
      });
    default:
      return state; // bypass
  }
}

import { ADD_EDIT_SONG } from "../actions/songs";

export default function songEdit(state = [], action = {}) {
  switch (action.type) {
    case ADD_EDIT_SONG:
      return action.songEdit;
    default:
      return state; // bypass
  }
}

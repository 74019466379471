import {
  GENRE_DELETED,
  GENRE_FETCHED,
  GENRE_SAVED,
  GENRE_UPDATED,
  SET_GENRES,
  SET_GENRE_ALBUMS,
} from "../actions/genres";

import { sortArrayByField } from "../utils/common";

export default function genres(state = [], action = {}) {
  switch (action.type) {
    // ---------------------------------------------------------
    // Genre
    // ---------------------------------------------------------
    case SET_GENRES:
      return action.genres;
    case GENRE_FETCHED:
      if (state.findIndex((item) => item.id === Number(action.genre.id)) > -1) {
        return state.map((item) => {
          if (
            item &&
            action &&
            action.genre &&
            item.id === Number(action.genre.id)
          )
            return action.genre;
          return item;
        });
      } else return [...state, action.genre];
    case GENRE_SAVED:
      return [...state, action.genre];
    case GENRE_UPDATED:
      return state.map((item) => {
        if (item.id === Number(action.genre.id)) return action.genre;
        return item;
      });
    case GENRE_DELETED:
      return state.filter((item) => item.id !== Number(action.genreId));
    case SET_GENRE_ALBUMS:
      return state.map((item) => {
        item.albums = [];
        if (item.id === Number(action.genreId)) item.albums = action.albums;
        return item;
      });
    // ---------------------------------------------------------
    // Album
    // ---------------------------------------------------------
    // case ALBUM_SAVED:
    //   let genre = state.find(
    //     (item) => item.id === Number(action.album.genre_id)
    //   );
    //   if (genre) {
    //     // If we already have albums recorded to the genre
    //     if (genre.albums && genre.albums.length > 0) {
    //       // Add the album to the genre
    //       genre.albums.push(action.album);
    //       // Sort albums by year
    //       let albums = genre.albums.sort((a, b) =>
    //         sortArrayByField(a, b, "year")
    //       );
    //       // Set sorted genres to the selected genre
    //       return state.map((item) => {
    //         if (item.id === Number(action.album.genre_id))
    //           item.albums = albums;
    //         return item;
    //       });
    //       // Just push the album to the empty array
    //     } else {
    //       return state.map((item) => {
    //         if (item.id === Number(action.album.genre_id))
    //           item.albums.push(action.album);
    //         return item;
    //       });
    //     }
    //   } else return state; // bypass
    // case ALBUM_UPDATED:
    //   return state.map((item) => {
    //     if (item.id === Number(action.album.genre_id)) {
    //       let album_index = item.albums.findIndex(
    //         (album) => album.id === Number(action.album.id)
    //       );
    //       if (album_index > -1) item.albums[album_index] = action.album;
    //     }
    //     return item;
    //   });
    // case ALBUM_DELETED:
    //   return state; // todo!
    default:
      return state; // bypass
  }
}

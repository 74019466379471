import { Button, Search } from "semantic-ui-react";
import {
  addEditSong,
  fetchSongSuggestions,
  searchSongs,
  songFetched,
} from "../../actions/songs";
import {
  searchRepertoireSong,
  setSongToCurrent,
} from "../../actions/repertoires";

import PropTypes from "prop-types";
import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

// Actions
const ADD_EXISTING_SONG_LABEL = "- - -Add existing song - -";
const ADD_NEW_SONG_LABEL = "- - -Add new song - -";

class SongsSearch extends React.Component {
  typingTimer;
  doneTypingInterval = 500;

  constructor(props) {
    console.log("SongSearch constructor");
    super(props);

    this.state = {
      loading: false,
      value: "",
    };
  }

  handleSearchChange = (e, data) => {
    this.setState({ value: data.value });

    if (data.value.trim() !== "") {
      clearTimeout(this.typingTimer);
      this.typingTimer = setTimeout(
        () => this.triggerSearch(data.value.trim()),
        this.doneTypingInterval
      );
    }
  };

  triggerSearch = (term) => {
    this.props.searchRepertoireSong(term);
    this.props.searchSongs(term);
    this.props.fetchSongSuggestions({ title: term });
  };

  handleSearchSelect = (e, data) => {
    console.log("handleSearchSelect", data);
    if (!data || !data.result || !data.result.song) return;
    if (data.result.song.type === "ignore") return;

    let song = data.result.song;

    switch (data.result.song.type) {
      case "repertoireSearchedSongs":
        console.log("repertoireSearchedSongs existing in repertoire");
        this.props
          .setSongToCurrent(
            { id: song.song_repertoire.repertoire_id },
            { id: song.id }
          )
          .then(() => {
            console.log(
              "repertoireSearchedSongs existing in repertoire throw event"
            );
            const event = new CustomEvent("current_song_changed", {
              detail: {
                repertoire_id: song.song_repertoire.repertoire_id,
                song_id: song.id,
              },
            });
            document.dispatchEvent(event);
          });
        return;
      case "songPropositions":
        console.log("handleSearchSelect existing");
        this.props.songFetched(song);
        break;
      case "songSuggestions":
        console.log("handleSearchSelect new");
        song.id = "new";
        this.props.addEditSong(song);
        break;
      default:
        break;
    }
    if (!this.props.history.location.pathname) return;
    const regex = /.(repertoire|genre)(=|\/)(\d+)/;
    let match = this.props.history.location.pathname.match(regex);
    if (!match) return;
    const [_, entity, _1, value] = match;
    this.props.history.push(`/song/${song.id}?${entity}=${value}`);
  };

  addType(songs, type) {
    if (!songs) return [];
    for (let i = 0; i < songs.length; i++) {
      songs[i].type = type;
    }
    return songs;
  }

  render() {
    const { loading, value } = this.state;
    let {
      repertoireSearchedSongs,
      songPropositions,
      songSuggestions,
    } = this.props;

    if (repertoireSearchedSongs && repertoireSearchedSongs.length > 0) {
      repertoireSearchedSongs = [
        ...this.addType(repertoireSearchedSongs, "repertoireSearchedSongs"),
        {
          singer: ADD_EXISTING_SONG_LABEL,
          title: "",
          type: "ignore",
        },
      ];
    }

    const songs = [
      ...(repertoireSearchedSongs || []),
      ...this.addType(songPropositions, "songPropositions"),
      { singer: ADD_NEW_SONG_LABEL, title: "", type: "ignore" },
      ...this.addType(songSuggestions, "songSuggestions"),
    ];

    return (
      <Search
        loading={loading}
        noResultsMessage="Nema rezultata!"
        onResultSelect={(e, data) => this.handleSearchSelect(e, data)}
        onSearchChange={this.handleSearchChange}
        results={songs.map((x, i) => {
          return {
            title: x.singer + " - " + x.title,
            key: i,
            song: x,
          };
        })}
        value={value}
        icon={
          !value ? (
            <i class="search icon" />
          ) : (
            <Button
              size="small"
              compact={true}
              onClick={() => this.setState({ value: "" })}
              style={{
                position: "absolute",
                right: 0,
                padding: "3px",
                margin: "10px",
              }}
            >
              <i class="close icon" />
            </Button>
          )
        }
      />
    );
  }
}

SongsSearch.propTypes = {
  repertoireSearchedSongs: PropTypes.array.isRequired,
  songSuggestions: PropTypes.array.isRequired,
  songPropositions: PropTypes.array.isRequired,
  searchSongs: PropTypes.func.isRequired,
  fetchSongSuggestions: PropTypes.func.isRequired,
  searchRepertoireSong: PropTypes.func.isRequired,
  addEditSong: PropTypes.func.isRequired,
  setSongToCurrent: PropTypes.func.isRequired,
};

function mapStateToProps(state) {
  return {
    repertoireSearchedSongs: state.repertoireSearchedSongs,
    songSuggestions: state.songSuggestions,
    songPropositions: state.songPropositions,
  };
}

export default withRouter(
  connect(
    mapStateToProps,
    {
      searchRepertoireSong,
      searchSongs,
      fetchSongSuggestions,
      addEditSong,
      songFetched,
      setSongToCurrent,
    }
  )(SongsSearch)
);

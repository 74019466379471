import authHeader from "../utils/auth-header";
import axios from "axios";

// -----------------------------------------------------------
// Action types
// -----------------------------------------------------------
export const SET_SONGS = "SET_SONGS";
export const SET_SEARCH_SONGS = "SET_SEARCH_SONGS";
export const ADD_SEARCH_SONGS = "ADD_SEARCH_SONGS";
export const SONG_FETCHED = "SONG_FETCHED";
export const SONG_SAVED = "SONG_SAVED";
export const SONG_UPDATED = "SONG_UPDATED";
export const SONG_DELETED = "SONG_DELETED";
export const SET_SONG_ALBUMS = "SET_SONG_ALBUMS";
export const ADD_EDIT_SONG = "ADD_EDIT_SONG";

// -----------------------------------------------------------
// Actions to be dispatched to the reducer
// -----------------------------------------------------------
export function setSongs(songs) {
  return { type: SET_SONGS, songs };
}
export function setSearchSongs(songPropositions) {
  return { type: SET_SEARCH_SONGS, songPropositions };
}
export function addSearchSongs(songSuggestions) {
  return { type: ADD_SEARCH_SONGS, songSuggestions };
}
export function songFetched(song) {
  return { type: SONG_FETCHED, song };
}
export function songSaved(song) {
  console.log("songSaved", song);
  song.albums = []; // Initialize with empty albums list
  return { type: SONG_SAVED, song };
}
export function songUpdated(song) {
  return { type: SONG_UPDATED, song };
}
export function songDeleted(songId) {
  return { type: SONG_DELETED, songId };
}
export function setSongAlbums(songId, albums) {
  return { type: SET_SONG_ALBUMS, songId, albums };
}
export function addEditSong(songEdit) {
  return { type: ADD_EDIT_SONG, songEdit };
}

// -----------------------------------------------------------
// Business rules before dispatch
// -----------------------------------------------------------
export function fetchSongs() {
  return (dispatch) => {
    return axios
      .get("/api/song", { headers: authHeader() })
      .then((response) => dispatch(setSongs(response.data.songs)))
      .catch((error) => console.log(error));
  };
}
export function searchSongs(query) {
  return (dispatch) => {
    return axios
      .get("/api/song/search", { params: { query }, headers: authHeader() })
      .then((response) => dispatch(setSearchSongs(response.data.songs)))
      .catch((error) => console.log(error));
  };
}
export function fetchSong(id) {
  return (dispatch) => {
    return axios
      .get(`/api/song/${id}`, { headers: authHeader() })
      .then((response) => dispatch(songFetched(response.data.song)))
      .catch((error) => console.log(error));
  };
}
export function fetchSongSuggestions(song) {
  return (dispatch) => {
    let { title } = song;
    return axios
      .post(`/api/song/suggestions`, { title }, { headers: authHeader() })
      .then((response) => dispatch(addSearchSongs(response.data.suggestions)))
      .catch((error) => console.log(error));
  };
}
export function getSongText(url) {
  return (dispatch) => {
    return axios
      .post(`/api/song/text`, { url }, { headers: authHeader() })
      .then((response) => response.data.text)
      .catch((error) => console.log(error));
  };
}
export function addSong(song) {
  return (dispatch) => {
    let { title, singer, text, tonality, repertoire, genre, genres } = song;
    return axios
      .post(
        "/api/song",
        {
          title,
          singer,
          text,
          tonality,
          repertoire,
          genre,
          genres,
        },
        { headers: authHeader() }
      )
      .then((response) => dispatch(songSaved(response.data.song)))
      .catch((error) => console.log(error));
  };
}
export function updateSong(song) {
  return (dispatch) => {
    let {
      id,
      title,
      singer,
      text,
      // repertoires,
      genres,
      tonality,
      repertoire,
      genre,
    } = song;
    return axios
      .put(
        `/api/song/${id}`,
        {
          id,
          title,
          singer,
          text,
          // repertoires,
          tonality,
          repertoire,
          genre,
          genres,
        },
        { headers: authHeader() }
      )
      .then((response) => dispatch(songUpdated(response.data.song)))
      .catch((error) => console.log(error));
  };
}
export function deleteSong(song) {
  return (dispatch) => {
    return axios
      .delete(`/api/song/${song.id}`, { headers: authHeader() })
      .then((response) => dispatch(songDeleted(response.data.song)))
      .catch((error) => console.log(error));
  };
}

import { REPERTOIRE_SONGS_FETCHED } from "../actions/repertoires";

export default function repertoireSearchedSongs(state = [], action = {}) {
  switch (action.type) {
    case REPERTOIRE_SONGS_FETCHED:
      return action.repertoireSearchedSongs || [];
    default:
      return state; // bypass
  }
}

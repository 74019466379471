import { NavLink, Redirect } from "react-router-dom";
import {
  addRepertoire,
  fetchRepertoire,
  importRepertoire,
  updateRepertoire,
} from "../../actions/repertoires";

import { Dropdown } from "semantic-ui-react";
import PropTypes from "prop-types";
import React from "react";
import SongSearch from "../Song/SongSearch";
import classnames from "classnames";
import { connect } from "react-redux";
import { fetchSongs } from "../../actions/songs";

/**
 * @description Repertoire form used to add and edit. We bind state and events binding to the component by constructor this time.
 * @extends React
 */
class RepertoireForm extends React.Component {
  file;

  constructor(props) {
    super(props);

    this.state = {
      id: "",
      title: "",
      songs: [],
      allSongs: [],
      errors: {},
      loading: false,
      redirect: false,
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  componentDidMount() {
    this.props.fetchSongs();
    if (
      this.props.match &&
      this.props.match.params &&
      typeof this.props.match.params.id !== "undefined" &&
      this.props.match.params.id !== "new"
    ) {
      this.props.fetchRepertoire(this.props.match.params.id);
    }
    // this.setState({
    // 	id: (this.props.repertoire) ? this.props.repertoire.id : '',
    // 	title: (this.props.repertoire) ? this.props.repertoire.title : '',
    // });
  }

  componentWillReceiveProps = (nextProps) => {
    if (nextProps.repertoire) {
      this.setState({
        id: nextProps.repertoire.id,
        title: nextProps.repertoire.title,
        songs: nextProps.repertoire.songs,
        allSongs: nextProps.allSongs,
      });
    }
  };

  handleChange(e) {
    this.setState({ [e.target.name]: e.target.value });
  }

  handleDropdownChange = (e, { value }) => {
    this.setState({
      songs: this.state.allSongs.filter((x) => value.indexOf("" + x.id) >= 0),
    });
  };

  handleSubmit(e) {
    e.preventDefault();
    let errors = {};

    // Validation
    if (this.state.title === "") errors.title = "This field can't be empty";

    // Fill the errors object state
    this.setState({ errors });

    // Proceed if everything is OK
    if (Object.keys(errors).length === 0) {
      const { id, title, songs } = this.state;
      this.setState({ loading: true });
      //this.props.saveRepertoire({ id, title });

      if (this.file) {
        this.props
          .importRepertoire({ id, title, songs }, this.file)
          .then(() => this.setState({ redirect: true }));
      } else {
        if (!id)
          this.props
            .addRepertoire({ title })
            .then(() => this.setState({ redirect: true }));
        else
          this.props
            .updateRepertoire({ id, title, songs })
            .then(() => this.setState({ redirect: true }));
      }
    }
  }

  onFileChange = (e, data) => {
    if (e.target.files.length <= 0) return;
    console.log("onFileChange", e.target.files[0]);
    this.file = e.target.files[0];
  };

  render() {
    return (
      <div>
        {// Redirect if some action has worked succesfully, render if not
        this.state.redirect ? (
          <Redirect to="/repertoires" />
        ) : (
          <div className="ui container">
            <h1>Uređivanje repertoara</h1>
            <NavLink exact to="/repertoires/" className="ui button">
              Nazad na listu repertoara
            </NavLink>
            <br />
            <br />
            <form
              className={classnames("ui", "form", {
                loading: this.state.loading,
              })}
              onSubmit={this.handleSubmit}
            >
              <h4 className="ui dividing header">
                Popunite formu ispod sa informacijama o repertoaru
              </h4>

              {!!this.state.errors.global && (
                <div className="ui negative message">
                  <p>{this.state.errors.global}</p>
                </div>
              )}

              <div
                className={classnames("field", {
                  error: !!this.state.errors.title,
                })}
              >
                <label htmlFor="title">Naziv</label>
                <input
                  type="text"
                  id="title"
                  name="title"
                  value={this.state.title}
                  className="ui input"
                  placeholder="Naziv repertoara"
                  onChange={this.handleChange}
                />
                <span>{this.state.errors.title}</span>
              </div>

              {/* {this.state.allSongs.length > 0 && this.state.songs && (
                <div>
                  <label htmlFor="Pjesme">Pjesme</label>
                  <Dropdown
                    placeholder="Pjesme"
                    fluid
                    multiple
                    selection
                    options={this.state.allSongs.map((x) => {
                      return {
                        key: JSON.stringify(x),
                        text: x.singer + " - " + x.title,
                        value: "" + x.id,
                      };
                    })}
                    value={this.state.songs.map((x) => "" + x.id)}
                    onChange={this.handleDropdownChange}
                  />
                </div>
              )} */}
              <label>Dodaj pjesmu u repertoar</label>
              <SongSearch />
              <label htmlFor="file">CSV fajl sa pjesmama</label>
              <input type="file" onChange={this.onFileChange} />
              <div className="field">
                <br />
                <button type="submit" className="ui primary button">
                  Snimi
                </button>
              </div>
            </form>
          </div>
        )}
      </div>
    );
  }
}

RepertoireForm.propTypes = {
  repertoire: PropTypes.object,
  fetchRepertoire: PropTypes.func.isRequired,
  updateRepertoire: PropTypes.func.isRequired,
  addRepertoire: PropTypes.func.isRequired,
  importRepertoire: PropTypes.func.isRequired,
  fetchSongs: PropTypes.func.isRequired,
};

function mapStateToProps(state, props) {
  if (props.match && props.match.params && props.match.params.id > 0) {
    return {
      repertoire: state.repertoires.find(
        (item) => item.id == props.match.params.id
      ),
      allSongs: state.songs,
    };
  }

  return { repertoire: null, allSongs: null };
}

export default connect(
  mapStateToProps,
  {
    fetchRepertoire,
    updateRepertoire,
    addRepertoire,
    fetchSongs,
    importRepertoire,
  }
)(RepertoireForm);

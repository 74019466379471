import { NavLink } from "react-router-dom";
import React from "react";
import SongSearch from "./Song/SongSearch";
import { connect } from "react-redux";
import logo from "../logo.png";

class Menu extends React.Component {
  render() {
    const { user: currentUser } = this.props;
    return (
      <div className="ui fixed inverted menu">
        {/* <NavLink
          className="header item"
          activeonlywhenexact="active"
          exact
          to="/"
        >
          <img className="logo App-logo" src={logo} alt="Logo" />
        </NavLink> */}
        {currentUser && (
          <NavLink
            className="item"
            activeonlywhenexact="active"
            exact
            to="/repertoires"
          >
            Repertoari
          </NavLink>
        )}
        {currentUser && (
          <NavLink
            className="item"
            activeonlywhenexact="active"
            exact
            to="/genres"
          >
            Žanrovi
          </NavLink>
        )}
        {currentUser && <SongSearch />}
      </div>
    );
  }
}

function mapStateToProps(state) {
  const { user } = state.auth;
  return {
    user,
  };
}

export default connect(mapStateToProps)(Menu);

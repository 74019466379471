// Style
import "./style/index.css";

// Redux
import { applyMiddleware, createStore } from "redux";

// The application, high order component
import App from "./components/App";
// Redux Provider for React
import { Provider } from "react-redux";
import React from "react";
import ReactDOM from "react-dom";
// Routing
import { Router } from "react-router-dom";
// Application routes as external component
import Routes from "./routes/Routes";
import axios from "axios";
// Redux Middlewares
import { composeWithDevTools } from "redux-devtools-extension";
import { createBrowserHistory } from "history";
// JSON Web Token
import jwt from "jsonwebtoken";
// API that helps in caching assets and other files when the user is offline or on slow network
import registerServiceWorker from "./registerServiceWorker";
// Root reducer to use in Redux Store
import rootReducer from "./reducers/rootReducer";
import thunk from "redux-thunk";

var host = window.location.hostname || "localhost";
var port = window.location.port || "80";

axios.defaults.baseURL = `http${
  window.location.protocol == "https:" ? "s" : ""
}://${host}${port != "80" && port != "443" ? ":" + port : ""}`;

// Browser history
const history = createBrowserHistory({
  // forceRefresh: true,
});

// Redux store
const store = createStore(
  rootReducer,
  composeWithDevTools(applyMiddleware(thunk))
);

ReactDOM.render(
  <Provider store={store}>
    <Router history={history}>
      <App>
        <Routes />
      </App>
    </Router>
  </Provider>,
  document.getElementById("root")
);
registerServiceWorker();

// For Arch Linux (if ENOSPC error) add this line to /etc/sysctl.d/99-sysctl.conf
// fs.inotify.max_user_watches=524288 | sudo tee -a /etc/sysctl.conf && sudo sysctl -
// Get the button:
let goToTop = document.getElementById("goToTop");

// When the user scrolls down 20px from the top of the document, show the button
window.onscroll = function() {
  scrollFunction();
};

function scrollFunction() {
  if (document.body.scrollTop > 20 || document.documentElement.scrollTop > 20) {
    goToTop.style.display = "block";
  } else {
    goToTop.style.display = "none";
  }
}

goToTop.onclick = () => {
  document.body.scrollTop = 0; // For Safari
  document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
};

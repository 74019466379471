import { Route, Switch } from "react-router-dom";

import GenreDelete from "../components/Genre/GenreDelete";
import GenreForm from "../components/Genre/GenreForm";
import GenreList from "../components/Genre/GenreList";
import GenreView from "../components/Genre/GenreView";
import Home from "../components/Home/Home";
import Login from "../components/Login/Login";
import React from "react";
import RepertoireDelete from "../components/Repertoire/RepertoireDelete";
import RepertoireForm from "../components/Repertoire/RepertoireForm";
import RepertoireList from "../components/Repertoire/RepertoireList";
import RepertoireView from "../components/Repertoire/RepertoireView";
import SongDelete from "../components/Song/SongDelete";
import SongForm from "../components/Song/SongForm";
import SongList from "../components/Song/SongList";
import authComponent from "../utils/auth.component";

class Routes extends React.Component {
  render() {
    return (
      <Switch>
        <Route exact path="/" component={authComponent(Home)} />
        <Route exact path="/login" component={Login} />
        <Route exact path="/songs" component={authComponent(SongList)} />
        <Route path="/song/new" component={authComponent(SongForm)} />
        <Route path="/song/:id/delete" component={authComponent(SongDelete)} />
        <Route path="/song/:id/edit" component={authComponent(SongForm)} />
        <Route path="/song/:id" component={authComponent(SongForm)} />
        <Route
          exact
          path="/repertoires"
          component={authComponent(RepertoireList)}
        />
        <Route
          path="/repertoire/new"
          component={authComponent(RepertoireForm)}
        />
        <Route
          path="/repertoire/:id/delete"
          component={authComponent(RepertoireDelete)}
        />
        <Route
          path="/repertoire/:id/edit"
          component={authComponent(RepertoireForm)}
        />
        <Route
          path="/repertoire/:id"
          component={authComponent(RepertoireView)}
        />
        <Route path="/genres" component={authComponent(GenreList)} />
        <Route path="/genre/new" component={authComponent(GenreForm)} />
        <Route
          path="/genre/:id/delete"
          component={authComponent(GenreDelete)}
        />
        <Route path="/genre/:id/edit" component={authComponent(GenreForm)} />
        <Route path="/genre/:id" component={authComponent(GenreView)} />
        <Route path="*" component={authComponent(Home)} />
      </Switch>
    );
  }
}

export default Routes;

import { Link, NavLink, withRouter } from "react-router-dom";
import { fetchGenre, setSongToCurrent } from "../../actions/genres";

import NoSleep from "nosleep.js";
import PropTypes from "prop-types";
import Push from "push.js";
import React from "react";
import classNames from "classnames";
import { connect } from "react-redux";

/**
 * @description Genre form used to add and edit. We bind state and events binding to the component by constructor this time.
 * @extends React
 */
class GenreForm extends React.Component {
  noSleep = null;

  constructor(props) {
    super(props);

    this.state = {
      id: "",
      title: "",
      songs: [],
      errors: {},
      loading: false,
      redirect: false,
    };
    this.subscribeToRepertoireSongCurrentAdded();
    this.subscribeToRepertoireSongCurrentChanged();
  }

  componentDidMount() {
    if (
      this.props.match &&
      this.props.match.params &&
      typeof this.props.match.params.id !== "undefined" &&
      this.props.match.params.id !== "new"
    ) {
      this.props.fetchGenre(this.props.match.params.id).then(() => {
        console.log("genre fetched 1");
      });
    }
    // this.setState({
    // 	id: (this.props.genre) ? this.props.genre.id : '',
    // 	title: (this.props.genre) ? this.props.genre.title : '',
    // });
    this.requestWakeLock();
    this.requestNotification();
  }

  componentWillUnmount() {
    this.disableWakeLock();
    this.unsubscribeFromRepertoireSongCurrentAdded();
    this.unsubscribeFromRepertoireSongCurrentChanged();
  }

  scrollToSong = (song) => {
    console.log("scrollToSong", song);
    try {
      document.getElementById("" + song).scrollIntoView();
      window.scrollBy(0, -50);
    } catch (e) {
      console.log(e);
    }
  };

  componentDidUpdate() {
    console.log("componentDidUpdate", this.state.songs);
    const songs = this.state.songs || [];
    let currentSong = songs.find((x) => !!(x.song_genre || {}).currentSong); //currentSongId || 0;
    if (songs && songs.length > 0 && !currentSong) currentSong = songs[0];
    let currentSongId = -1;
    if (currentSong) currentSongId = currentSong.id;
    this.scrollToSong(currentSongId);
  }

  componentWillReceiveProps = (nextProps) => {
    if (nextProps.genre) {
      this.setState({
        id: nextProps.genre.id,
        title: nextProps.genre.title,
        songs: nextProps.genre.songs,
      });
    }
  };

  requestWakeLock = async () => {
    this.noSleep = new NoSleep();
    var noSleep = this.noSleep;
    // Enable wake lock.
    // (must be wrapped in a user input event handler e.g. a mouse or touch handler)
    document.addEventListener(
      "click",
      function enableNoSleep() {
        document.removeEventListener("click", enableNoSleep, false);
        noSleep.enable();
      },
      false
    );
  };

  requestNotification() {
    // TODO: mora piciti preko https da bi radile notifikacije...
    document.addEventListener(
      "click",
      function enablePush() {
        Push.Permission.request(
          () => {
            console.log("granted");
            document.removeEventListener("click", enablePush, false);
          },
          () => {
            console.log("not granted");
            document.removeEventListener("click", enablePush, false);
          }
        );
        //   Push.create("Hello world!", {
        //     body: "How's it hangin'?",
        //     icon: "/images/icon.png",
        //     link: "/#",
        //     timeout: 4000,
        //     onClick: function() {
        //       console.log("Fired!");
        //       window.focus();
        //       document.removeEventListener("click", enablePush, false);
        //       this.close();
        //     },
        //     vibrate: [200, 100, 200, 100, 200, 100, 200],
        //   });
      },
      false
    );
  }

  disableWakeLock = () => {
    if (this.noSleep) {
      this.noSleep.disable();
    }
  };

  onRepertoireSongCurrentChanged = (e) => {
    console.log("onRepertoireSongCurrentChanged", e);
    // var [url, currentSong] = location.hash.split("#");
    // var urlSplitted = url.split("/");
    var repertoireId = e.detail.repertoire_id;
    if (repertoireId) {
      this.props.history.push(`/repertoire/${repertoireId}`);
      return;
    }
    var genreId = e.detail.genre_id;
    if (genreId && genreId != this.props.match.params.id) {
      this.props.history.push(`/genre/${genreId}`);
      window.location.reload();
      return;
    }
    var currentSong = e.detail.song_id;
    if (!currentSong) return;
    console.log("onRepertoireSongCurrentChanged", currentSong);
    let songs = this.state.songs || [];
    this.setState({
      // currentSongId: currentSong,
      songs: songs.map((x) => {
        (x.song_genre || {}).currentSong = x.id == currentSong;
        return x;
      }),
    });
    this.scrollToSong(currentSong);
  };

  subscribeToRepertoireSongCurrentChanged = () => {
    console.log("subscribeToRepertoireSongCurrentChanged");
    document.addEventListener(
      "current_song_changed",
      this.onRepertoireSongCurrentChanged
    );
  };

  unsubscribeFromRepertoireSongCurrentChanged = () => {
    console.log("unsubscribeFromRepertoireSongCurrentChanged");
    document.removeEventListener(
      "current_song_changed",
      this.onRepertoireSongCurrentChanged
    );
  };

  onRepertoireSongCurrentAdded = (e) => {
    console.log("onRepertoireSongCurrentAdded", e);
    if (!e.detail.id) return;
    let song = e.detail;
    let idx =
      this.state.songs.findIndex((x) => (x.song_genre || {}).currentSong) || -1;
    idx++;
    console.log("onRepertoireSongCurrentAdded idx", idx, song);
    let songs = this.state.songs.filter((x) => x.id != song.id);
    songs.splice(idx, 0, song);
    console.log("onRepertoireSongCurrentAdded songs", songs);
    this.setState({ songs });
  };

  subscribeToRepertoireSongCurrentAdded = () => {
    console.log("subscribeToRepertoireSongCurrentAdded");
    document.addEventListener("song_added", this.onRepertoireSongCurrentAdded);
  };

  unsubscribeFromRepertoireSongCurrentAdded = () => {
    console.log("unsubscribeFromRepertoireSongCurrentAdded");
    document.removeEventListener(
      "song_added",
      this.onRepertoireSongCurrentAdded
    );
  };

  changeCurrentSong = (song) => {
    console.log("changeCurrentSong", song);
    let songs = this.state.songs || [];
    this.setState({
      // currentSongId: song.id,
      songs: songs.map((x) => {
        (x.song_genre || {}).currentSong = x.id === song.id;
        return x;
      }),
    });
    this.props.setSongToCurrent({ id: this.state.id }, { id: song.id });
  };

  getYouTubeURL(song) {
    let { title, singer } = song;
    let queryParams = new URLSearchParams("");
    queryParams.set("search_query", (singer + " " + title).trim());
    return "https://www.youtube.com/results?" + queryParams.toString();
  }

  render() {
    let gridLines = "";

    const songs = this.state.songs || [];
    let currentSong = songs.find((x) => !!(x.song_genre || {}).currentSong); //currentSongId || 0;
    if (songs && songs.length > 0 && !currentSong) currentSong = songs[0];
    let currentSongId = -1;
    if (currentSong) currentSongId = currentSong.id;
    console.log("render", currentSongId);

    if (songs && songs.length !== 0) {
      gridLines = songs.map((song, i) => {
        if (song) {
          return (
            <tr key={song.id}>
              <td>
                <table className="genreView">
                  <tbody>
                    <tr>
                      <td onClick={() => this.changeCurrentSong(song)}>
                        <strong
                          id={song.id}
                          onClick={() => this.changeCurrentSong(song)}
                        >
                          {i + 1}.&nbsp;&nbsp;&nbsp;&nbsp;{song.title} -{" "}
                          {song.singer} - {song.tonality}
                        </strong>
                      </td>
                      <td>
                        <a
                          className="ui button compact icon small editSongButton"
                          target="_blank"
                          href={this.getYouTubeURL(song)}
                        >
                          <i className="icon youtube" />
                        </a>
                        <NavLink
                          className="ui button compact icon small blue editSongButton"
                          to={`/song/${song.id}/edit`}
                          title="Edit"
                        >
                          <i className="icon edit" />
                        </NavLink>
                      </td>
                    </tr>
                    {currentSongId == song.id && song.text && (
                      <tr>
                        <td colSpan={2}>
                          {song.text.split("\n").map((x, i) => (
                            <div key={i}>{x === "" ? <br /> : x}</div>
                          ))}
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </td>
            </tr>
          );
        }
      });
    }

    const grid = (
      <table className="ui striped selectable compact table single line">
        <tbody>{gridLines}</tbody>
      </table>
    );

    const emptyMessage = (
      <div className="ui info message">Trenutno nema pjesama</div>
    );

    const host = window.location.hostname || "localhost";

    return (
      <div className="ui container">
        <h1>Žanr: {this.state.title}</h1>

        <NavLink exact to="/genres/" className="ui button">
          Nazad na listu žanrova
        </NavLink>
        <br />
        {songs && songs.length > 0 ? grid : emptyMessage}
        <br />
      </div>
    );
  }
}

GenreForm.propTypes = {
  genre: PropTypes.object,
  fetchGenre: PropTypes.func.isRequired,
  setSongToCurrent: PropTypes.func.isRequired,
};

function mapStateToProps(state, props) {
  if (props.match && props.match.params && props.match.params.id > 0) {
    return {
      genre: state.genres.find(
        (item) => item && item.id == props.match.params.id
      ),
    };
  }

  return { genre: null };
}

export default withRouter(
  connect(
    mapStateToProps,
    { fetchGenre, setSongToCurrent }
  )(GenreForm)
);

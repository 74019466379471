import { NavLink, withRouter } from "react-router-dom";

import PropTypes from "prop-types";
import React from "react";
import classnames from "classnames";
import { connect } from "react-redux";
// Actions
import { fetchRepertoires } from "../../actions/repertoires";

class RepertoiresListPage extends React.Component {
  /**
   * Fetch all repertoires on component load
   */
  componentDidMount() {
    this.props.fetchRepertoires();
  }

  openRepertoire = (id) => {
    return () => {
      return this.props.history.push(`/repertoire/${id}`);
    };
  };

  render() {
    let gridLines = "";

    const repertoires = this.props.repertoires;

    if (repertoires && repertoires.length !== 0) {
      gridLines = repertoires.map((repertoire, i) => {
        if (repertoire) {
          return (
            <tr key={repertoire.id}>
              <td onClick={this.openRepertoire(repertoire.id)}>{i + 1}</td>
              <td onClick={this.openRepertoire(repertoire.id)}>
                {repertoire.title}
              </td>
              <td style={{ textAlign: "right" }}>
                <NavLink
                  className="ui button compact icon small black"
                  to={`/repertoire/${repertoire.id}`}
                  title="More details"
                >
                  <i className="icon eye" />
                </NavLink>
                <NavLink
                  className="ui button compact icon small blue"
                  to={`/repertoire/${repertoire.id}/edit`}
                  title="Edit"
                >
                  <i className="icon edit" />
                </NavLink>
                <NavLink
                  className="ui button compact icon small red"
                  to={`/repertoire/${repertoire.id}/delete`}
                  title="Delete"
                >
                  <i className="icon trash" />
                </NavLink>
              </td>
            </tr>
          );
        }
      });
    }

    const grid = (
      <table className="ui striped selectable compact table single line">
        <thead>
          <tr>
            <th width="7%">ID</th>
            <th>Naziv</th>
            <th width="15%" />
          </tr>
        </thead>
        <tbody>{gridLines}</tbody>
      </table>
    );

    const emptyMessage = (
      <div className="ui info message">Trenutno nema repertoara</div>
    );

    return (
      <div className="ui container">
        <h1>Repertoari</h1>
        <NavLink exact to="/repertoire/new" className="ui button primary">
          Dodaj
        </NavLink>
        {repertoires && repertoires.length > 0 ? grid : emptyMessage}
      </div>
    );
  }
}

RepertoiresListPage.propTypes = {
  repertoires: PropTypes.array.isRequired,
  fetchRepertoires: PropTypes.func.isRequired,
};

function mapStateToProps(state) {
  return {
    repertoires: state.repertoires,
  };
}

export default withRouter(
  connect(
    mapStateToProps,
    { fetchRepertoires }
  )(RepertoiresListPage)
);

import { Link, NavLink, withRouter } from "react-router-dom";
import {
  fetchRepertoire,
  moveSongDown,
  moveSongUp,
  removeSong,
  setSongToCurrent,
} from "../../actions/repertoires";

import NoSleep from "nosleep.js";
import PropTypes from "prop-types";
import Push from "push.js";
import React from "react";
import classNames from "classnames";
import { connect } from "react-redux";
import { fetchDefaultRepertoire } from "../../actions/repertoires";

/**
 * @description Repertoire form used to add and edit. We bind state and events binding to the component by constructor this time.
 * @extends React
 */
class RepertoireForm extends React.Component {
  noSleep = null;

  constructor(props) {
    super(props);

    this.state = {
      id: "",
      title: "",
      songs: [],
      errors: {},
      loading: false,
      redirect: false,
    };
    this.subscribeToRepertoireSongCurrentAdded();
    this.subscribeToRepertoireSongCurrentChanged();
    this.subscribeToAppWasOffline();
  }

  componentDidMount() {
    if (
      this.props.match &&
      this.props.match.params &&
      typeof this.props.match.params.id !== "undefined" &&
      this.props.match.params.id !== "new"
    ) {
      this.props
        .fetchRepertoire(this.props.match.params.id)
        .then((repertoire) => {
          if (!repertoire) {
            repertoire = {};
          }
          if (!repertoire.songs) {
            repertoire = repertoire.repertoire || {};
          }
          if (this.state.songs.length != repertoire.songs.length) {
            this.setState({ ...repertoire.repertoire });
          }
          return repertoire;
        });
    }
    // this.setState({
    // 	id: (this.props.repertoire) ? this.props.repertoire.id : '',
    // 	title: (this.props.repertoire) ? this.props.repertoire.title : '',
    // });
    this.requestWakeLock();
    this.requestNotification();
  }

  componentWillUnmount() {
    this.disableWakeLock();
    this.unsubscribeFromRepertoireSongCurrentAdded();
    this.unsubscribeFromRepertoireSongCurrentChanged();
    this.unsubscribeToAppWasOffline();
  }

  componentDidUpdate() {
    console.log("componentDidUpdate", this.state.songs);
    const songs = this.state.songs || [];
    let currentSong = songs.find(
      (x) => !!(x.song_repertoire || {}).currentSong
    ); //currentSongId || 0;
    if (songs && songs.length > 0 && !currentSong) currentSong = songs[0];
    let currentSongId = -1;
    if (currentSong) currentSongId = currentSong.id;
    this.scrollToSong(currentSongId);
  }

  componentWillReceiveProps = (nextProps) => {
    if (nextProps.repertoire) {
      this.setState({
        id: nextProps.repertoire.id,
        title: nextProps.repertoire.title,
        songs: nextProps.repertoire.songs,
      });
    }
  };

  subscribeToAppWasOffline = () => {
    console.log("subscribeToAppWasOffline");
    document.addEventListener("socket_connect", this.onAppWasOffline);
  };

  unsubscribeToAppWasOffline = () => {
    document.removeEventListener("socket_connect", this.onAppWasOffline);
  };

  subscribeToRepertoireSongCurrentChanged = () => {
    console.log("subscribeToRepertoireSongCurrentChanged");
    document.addEventListener(
      "current_song_changed",
      this.onRepertoireSongCurrentChanged
    );
  };

  unsubscribeFromRepertoireSongCurrentChanged = () => {
    console.log("unsubscribeFromRepertoireSongCurrentChanged");
    document.removeEventListener(
      "current_song_changed",
      this.onRepertoireSongCurrentChanged
    );
  };

  subscribeToRepertoireSongCurrentAdded = () => {
    console.log("subscribeToRepertoireSongCurrentAdded");
    document.addEventListener("song_added", this.onRepertoireSongCurrentAdded);
  };

  unsubscribeFromRepertoireSongCurrentAdded = () => {
    console.log("unsubscribeFromRepertoireSongCurrentAdded");
    document.removeEventListener(
      "song_added",
      this.onRepertoireSongCurrentAdded
    );
  };

  scrollToSong = (song) => {
    if (!song) return;
    console.log("scrollToSong", song);
    try {
      let songElement = document.getElementById("" + song);
      if (songElement) {
        songElement.scrollIntoView();
        window.scrollBy(0, -50);
      }
    } catch (e) {
      console.log(e);
    }
  };

  requestWakeLock = async () => {
    this.noSleep = new NoSleep();
    var noSleep = this.noSleep;
    // Enable wake lock.
    // (must be wrapped in a user input event handler e.g. a mouse or touch handler)
    document.addEventListener(
      "click",
      function enableNoSleep() {
        document.removeEventListener("click", enableNoSleep, false);
        noSleep.enable();
      },
      false
    );
  };

  requestNotification() {
    // TODO: mora piciti preko https da bi radile notifikacije...
    document.addEventListener(
      "click",
      function enablePush() {
        Push.Permission.request(
          () => {
            console.log("granted");
            document.removeEventListener("click", enablePush, false);
          },
          () => {
            console.log("not granted");
            document.removeEventListener("click", enablePush, false);
          }
        );
        //   Push.create("Hello world!", {
        //     body: "How's it hangin'?",
        //     icon: "/images/icon.png",
        //     link: "/#",
        //     timeout: 4000,
        //     onClick: function() {
        //       console.log("Fired!");
        //       window.focus();
        //       document.removeEventListener("click", enablePush, false);
        //       this.close();
        //     },
        //     vibrate: [200, 100, 200, 100, 200, 100, 200],
        //   });
      },
      false
    );
  }

  disableWakeLock = () => {
    if (this.noSleep) {
      this.noSleep.disable();
    }
  };

  onRepertoireSongCurrentChanged = (e) => {
    console.log("onRepertoireSongCurrentChanged", e);
    // var [url, currentSong] = location.hash.split("#");
    // var urlSplitted = url.split("/");
    var repertoireId = e.detail.repertoire_id;
    if (repertoireId != this.props.match.params.id) {
      this.props.history.push(`/repertoire/${repertoireId}`);
      this.props.fetchRepertoire(repertoireId).then((repertoire) => {
        this.setState({ ...repertoire.repertoire }, () => {
          this.changeCurrentSong({ id: e.detail.song_id }, true);
        });
      });
      return;
    }
    var genreId = e.detail.genre_id;
    if (genreId) {
      this.props.history.push(`/genre/${genreId}`);
      return;
    }
    var currentSong = e.detail.song_id;
    if (!currentSong) return;
    console.log("onRepertoireSongCurrentChanged currentSong", currentSong);
    let songs = this.state.songs || [];
    this.setState(
      {
        // currentSongId: currentSong,
        songs: songs.map((x) => {
          (x.song_repertoire || {}).currentSong = x.id == currentSong;
          return x;
        }),
      },
      () => this.scrollToSong(currentSong)
    );
  };

  onAppWasOffline = (e) => {
    console.log("onAppWasOffline", e);
    this.props.fetchDefaultRepertoire().then((repertoire) => {
      console.log("default repertoire fetched", repertoire);
      this.props.fetchRepertoire(repertoire.id).then((data) => {
        console.log("repertoire fetched", data);
        this.setState({ ...data.repertoire });
      });
    });
  };

  onRepertoireSongCurrentAdded = (e) => {
    console.log("onRepertoireSongCurrentAdded", e);
    if (!e.detail.id) return;
    let song = e.detail || {};
    let song_repertoire =
      song.song_repertoire ||
      ((song.repertoires || []).find((x) => x.id == this.state.id) || {})
        .song_repertoire;
    if (!song.song_repertoire && song_repertoire) {
      song.song_repertoire = song_repertoire;
    }
    let idx = this.state.songs.findIndex(
      (x) => (x.song_repertoire || {}).ordinal > (song_repertoire || {}).ordinal
    );
    console.log("onRepertoireSongCurrentAdded idx", idx, song);
    let songs = this.state.songs.filter((x) => x.id != song.id);
    if (idx < 0) {
      songs.push(song);
    } else {
      songs.splice(idx, 0, song);
    }
    console.log("onRepertoireSongCurrentAdded songs", songs);
    this.setState({ songs });
  };

  changeCurrentSong = (song, skipNotifySetSongToCurrent) => {
    let songs = this.state.songs || [];
    console.log("changeCurrentSong", song, songs);
    this.setState(
      {
        // currentSongId: song.id,
        songs: songs.map((x) => {
          let song_repertoire = x.song_repertoire || {};
          song_repertoire.currentSong = x.id == song.id;
          x.song_repertoire = song_repertoire;
          return x;
        }),
      },
      () => {
        console.log("after changeCurrentSong", this.state.songs);
        if (!skipNotifySetSongToCurrent) {
          this.props.setSongToCurrent({ id: this.state.id }, { id: song.id });
        }
      }
    );
  };

  downloadRepertoireCSV = () => {
    console.log("downloadRepertoireCSV");
    const user = JSON.parse(localStorage.getItem("user"));
    fetch(`/api/repertoire/${this.state.id}/csv?token=${user.token}`).then(
      (response) => {
        response.blob().then((blob) => {
          let url = window.URL.createObjectURL(blob);
          let a = document.createElement("a");
          a.href = url;
          a.download = `repertoire_${this.state.id}.csv`;
          a.click();
        });
        //window.location.href = response.url;
      }
    );
  };

  removeRepertoireSong = (song) => {
    this.props.removeSong(this.state, song).then(() => {
      this.setState({
        songs: this.state.songs.filter((s) => s.id !== song.id),
      });
    });
  };

  moveUpRepertoireSong = (song) => {
    this.props.moveSongUp(this.state, song).then((repertoire) => {
      this.setState({
        songs: repertoire.songs,
      });
    });
  };

  moveDownRepertoireSong = (song) => {
    this.props.moveSongDown(this.state, song).then((repertoire) => {
      this.setState({
        songs: repertoire.songs,
      });
    });
  };

  getYouTubeURL(song) {
    let { title, singer } = song;
    let queryParams = new URLSearchParams("");
    queryParams.set("search_query", (singer + " " + title).trim());
    return "https://www.youtube.com/results?" + queryParams.toString();
  }

  render() {
    let gridLines = "";

    const songs = this.state.songs || [];
    console.log(songs);
    let currentSong = songs.find(
      (x) => !!(x.song_repertoire || {}).currentSong
    ); //currentSongId || 0;
    if (songs && songs.length > 0 && !currentSong) currentSong = songs[0];
    let currentSongId = -1;
    if (currentSong) currentSongId = currentSong.id;
    console.log("render", currentSongId);

    if (songs && songs.length !== 0) {
      gridLines = songs.map((song, i) => {
        if (song) {
          return (
            <tr key={song.id}>
              <td>
                <table className="repertoireView">
                  <tbody>
                    <tr>
                      <td onClick={() => this.changeCurrentSong(song)}>
                        <strong id={song.id}>
                          {i + 1}.&nbsp;&nbsp;&nbsp;&nbsp;{song.title} -{" "}
                          {song.singer} - {song.tonality}
                        </strong>
                      </td>
                      <td>
                        <a
                          className="ui button compact icon small editSongButton"
                          target="_blank"
                          href={this.getYouTubeURL(song)}
                        >
                          <i className="icon youtube" />
                        </a>
                        <NavLink
                          className="ui button compact icon small blue editSongButton"
                          to={`/song/${song.id}/edit`}
                          title="Edit"
                        >
                          <i className="icon edit" />
                        </NavLink>
                        {this.state.editing && (
                          <div className="repertoireSongButtonGroup">
                            <button
                              className="ui button compact icon small red removeRepertoireSongButton"
                              title="Delete"
                              onClick={() => this.removeRepertoireSong(song)}
                            >
                              <i className="icon delete" />
                            </button>
                            <button
                              className="ui button compact icon small moveUpButton"
                              title="MoveUp"
                              onClick={() => this.moveUpRepertoireSong(song)}
                            >
                              <i className="icon arrow alternate circle up" />
                            </button>
                            <button
                              className="ui button compact icon small moveDownButton"
                              title="MoveDown"
                              onClick={() => this.moveDownRepertoireSong(song)}
                            >
                              <i className="icon arrow circle down" />
                            </button>
                          </div>
                        )}
                      </td>
                      <td onClick={() => this.changeCurrentSong(song)}>
                        {song.genres &&
                          song.genres.map((genre) => {
                            return (
                              <p
                                className="genre"
                                style={{ backgroundColor: genre.color }}
                              >
                                {genre.title}
                              </p>
                            );
                          })}
                      </td>
                    </tr>
                    {currentSongId == song.id && song.text && (
                      <tr>
                        <td colSpan={3}>
                          {song.text.split("\n").map((x, i) => (
                            <div key={i}>{x === "" ? <br /> : x}</div>
                          ))}
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </td>
            </tr>
          );
        }
      });
    }

    const grid = (
      <table className="ui striped selectable compact table single line">
        <tbody>{gridLines}</tbody>
      </table>
    );

    const emptyMessage = (
      <div className="ui info message">Trenutno nema pjesama</div>
    );

    return (
      <div className="ui container">
        <h1>Repertoar: {this.state.title}</h1>

        <NavLink exact to="/repertoires/" className="ui button">
          Nazad na listu repertoara
        </NavLink>
        <button
          className="ui button"
          onClick={() => this.setState({ editing: !this.state.editing })}
          inline="true"
        >
          Uredi repertoar
        </button>
        <button
          style={{ float: "right" }}
          className="ui button"
          onClick={this.downloadRepertoireCSV}
          inline="true"
        >
          Download
        </button>
        <br />
        {songs && songs.length > 0 ? grid : emptyMessage}
        <br />
      </div>
    );
  }
}

RepertoireForm.propTypes = {
  repertoire: PropTypes.object,
  fetchRepertoire: PropTypes.func.isRequired,
  setSongToCurrent: PropTypes.func.isRequired,
  removeSong: PropTypes.func.isRequired,
  moveSongUp: PropTypes.func.isRequired,
};

function mapStateToProps(state, props) {
  if (props.match && props.match.params && props.match.params.id > 0) {
    return {
      repertoire: state.repertoires.find(
        (item) => item && item.id == props.match.params.id
      ),
    };
  }

  return { repertoire: null };
}

export default withRouter(
  connect(
    mapStateToProps,
    {
      fetchRepertoire,
      setSongToCurrent,
      removeSong,
      moveSongUp,
      moveSongDown,
      fetchDefaultRepertoire,
    }
  )(RepertoireForm)
);

import { SET_SEARCH_SONGS } from "../actions/songs";

export default function songPropositions(state = [], action = {}) {
  switch (action.type) {
    case SET_SEARCH_SONGS:
      return action.songPropositions || [];
    default:
      return state; // bypass
  }
}

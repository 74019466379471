import { ADD_SEARCH_SONGS } from "../actions/songs";

export default function songSuggestions(state = [], action = {}) {
  switch (action.type) {
    case ADD_SEARCH_SONGS:
      return action.songSuggestions || [];
    default:
      return state; // bypass
  }
}

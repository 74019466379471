import auth from "./auth";
// Actions
import { combineReducers } from "redux";
import genres from "./genres";
import repertoireSearchedSongs from "./repertoireSearchedSongs";
import repertoires from "./repertoires";
import songEdit from "./songEdit";
import songPropositions from "./songPropositions";
import songSuggestions from "./songSuggestions";
import songs from "./songs";

// Used as a single reducer to create the Redux store
export default combineReducers({
  repertoires,
  songs,
  songSuggestions,
  songPropositions,
  songEdit,
  genres,
  auth,
  repertoireSearchedSongs,
});

import React, { Component } from "react";

import Footer from "./Footer";
import Menu from "./Menu";
import { Redirect } from "react-router-dom";
import { connect } from "react-redux";
import socketIOClient from "socket.io-client";
import { withRouter } from "react-router-dom";

var host = window.location.hostname || "localhost";
var port = window.location.port || "80";
if (process.env.NODE_ENV === "development") {
  port = "3000";
}

class App extends Component {
  componentWillMount() {
    const socket = socketIOClient(
      `http${window.location.protocol == "https:" ? "s" : ""}://${host}${
        port != "80" && port != "443" ? ":" + port : ""
      }`
    );

    socket.on("current_song_changed", (data) => {
      const event = new CustomEvent("current_song_changed", {
        detail: data,
      });
      document.dispatchEvent(event);
    });
    socket.on("song_added", (data) => {
      const event = new CustomEvent("song_added", {
        detail: data.song,
      });
      document.dispatchEvent(event);
    });
    socket.on("connect", function() {
      console.log("connected to server");
    });
    socket.on("disconnect", function() {
      console.log("disconnected from server");
      setTimeout(() => {
        socket.connect();
      }, 1389);
    });
    socket.io.on("reconnect", () => {
      console.log("socket io reconnect", socket.id); // x8WIv7-mJelg7on_ALbx
      document.dispatchEvent(
        new CustomEvent("socket_connect", { detail: socket.id })
      );
    });
    socket.on("connect_error", () => {
      console.log("socket connect_error", socket.id);
      setTimeout(() => {
        socket.connect();
      }, 1000);
    });
  }
  render() {
    return (
      <div>
        <Menu />

        {/* .main from index.css file */}
        <div className="ui container text main">{this.props.children}</div>

        <Footer />
      </div>
    );
  }
}

function mapStateToProps(state) {
  const { user } = state.auth;
  return {
    user,
  };
}

export default withRouter(connect(mapStateToProps)(App));

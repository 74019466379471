import { NavLink, withRouter } from "react-router-dom";

import PropTypes from "prop-types";
import React from "react";
import classnames from "classnames";
import { connect } from "react-redux";
// Actions
import { fetchGenres } from "../../actions/genres";

class GenresListPage extends React.Component {
  /**
   * Fetch all genres on component load
   */
  componentDidMount() {
    this.props.fetchGenres();
  }

  openGenre = (id) => {
    return () => {
      return this.props.history.push(`/genre/${id}`);
    };
  };

  render() {
    let gridLines = "";

    const genres = this.props.genres;

    if (genres && genres.length !== 0) {
      gridLines = genres.map((genre, i) => {
        if (genre) {
          return (
            <tr key={genre.id}>
              <td onClick={this.openGenre(genre.id)}>{genre.id}</td>
              <td onClick={this.openGenre(genre.id)}>{genre.title}</td>
              <td
                onClick={this.openGenre(genre.id)}
                style={{ backgroundColor: genre.color }}
              >
                {/* {genre.color} */}
              </td>
              <td style={{ textAlign: "right" }}>
                <NavLink
                  className="ui button compact icon small black"
                  to={`/genre/${genre.id}`}
                  title="More details"
                >
                  <i className="icon eye" />
                </NavLink>
                <NavLink
                  className="ui button compact icon small blue"
                  to={`/genre/${genre.id}/edit`}
                  title="Edit"
                >
                  <i className="icon edit" />
                </NavLink>
                <NavLink
                  className="ui button compact icon small red"
                  to={`/genre/${genre.id}/delete`}
                  title="Delete"
                >
                  <i className="icon trash" />
                </NavLink>
              </td>
            </tr>
          );
        }
      });
    }

    const grid = (
      <table className="ui striped selectable compact table single line">
        <thead>
          <tr>
            <th width="7%">ID</th>
            <th>Naziv</th>
            <th>Boja</th>
            <th width="15%" />
          </tr>
        </thead>
        <tbody>{gridLines}</tbody>
      </table>
    );

    const emptyMessage = (
      <div className="ui info message">Trenutno nema žanrova</div>
    );

    return (
      <div className="ui container">
        <h1>Žanrovi</h1>
        <NavLink exact to="/genre/new" className="ui button primary">
          Dodaj
        </NavLink>
        {genres && genres.length > 0 ? grid : emptyMessage}
      </div>
    );
  }
}

GenresListPage.propTypes = {
  genres: PropTypes.array.isRequired,
  fetchGenres: PropTypes.func.isRequired,
};

function mapStateToProps(state) {
  return {
    genres: state.genres,
  };
}

export default withRouter(
  connect(
    mapStateToProps,
    { fetchGenres }
  )(GenresListPage)
);

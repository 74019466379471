import {
  SET_SONGS,
  SET_SONG_ALBUMS,
  SONG_DELETED,
  SONG_FETCHED,
  SONG_SAVED,
  SONG_UPDATED,
} from "../actions/songs";

import { sortArrayByField } from "../utils/common";

export default function songs(state = [], action = {}) {
  switch (action.type) {
    // ---------------------------------------------------------
    // Song
    // ---------------------------------------------------------
    case SET_SONGS:
      return action.songs;
    case SONG_FETCHED:
      if (state.findIndex((item) => item.id === action.song.id) > -1) {
        return state.map((item) => {
          if (item.id === action.song.id) return action.song;
          return item;
        });
      } else return [...state, action.song];
    case SONG_SAVED:
      console.log("SONG_SAVED", [...state, action.song]);
      return [...state, action.song];
    case SONG_UPDATED:
      return state.map((item) => {
        if (item.id === action.song.id) return action.song;
        return item;
      });
    case SONG_DELETED:
      return state.filter((item) => item.id !== action.songId);
    case SET_SONG_ALBUMS:
      return state.map((item) => {
        item.albums = [];
        if (item.id === action.songId) item.albums = action.albums;
        return item;
      });
    // ---------------------------------------------------------
    // Album
    // ---------------------------------------------------------
    // case ALBUM_SAVED:
    //   let song = state.find((item) => item.id === Number(action.album.song_id));
    //   if (song) {
    //     // If we already have albums recorded to the song
    //     if (song.albums && song.albums.length > 0) {
    //       // Add the album to the song
    //       song.albums.push(action.album);
    //       // Sort albums by year
    //       let albums = song.albums.sort((a, b) =>
    //         sortArrayByField(a, b, "year")
    //       );
    //       // Set sorted songs to the selected song
    //       return state.map((item) => {
    //         if (item.id === Number(action.album.song_id)) item.albums = albums;
    //         return item;
    //       });
    //       // Just push the album to the empty array
    //     } else {
    //       return state.map((item) => {
    //         if (item.id === Number(action.album.song_id))
    //           item.albums.push(action.album);
    //         return item;
    //       });
    //     }
    //   } else return state; // bypass
    // case ALBUM_UPDATED:
    //   return state.map((item) => {
    //     if (item.id === Number(action.album.song_id)) {
    //       let album_index = item.albums.findIndex(
    //         (album) => album.id === Number(action.album.id)
    //       );
    //       if (album_index > -1) item.albums[album_index] = action.album;
    //     }
    //     return item;
    //   });
    // case ALBUM_DELETED:
    //   return state; // todo!
    default:
      return state; // bypass
  }
}

import authHeader from "../utils/auth-header";
import axios from "axios";

// -----------------------------------------------------------
// Action types
// -----------------------------------------------------------
export const SET_GENRES = "SET_GENRES";
export const GENRE_FETCHED = "GENRE_FETCHED";
export const GENRE_SAVED = "GENRE_SAVED";
export const GENRE_UPDATED = "GENRE_UPDATED";
export const GENRE_DELETED = "GENRE_DELETED";
export const SET_GENRE_ALBUMS = "SET_GENRE_ALBUMS";

// -----------------------------------------------------------
// Actions to be dispatched to the reducer
// -----------------------------------------------------------
export function setGenres(genres) {
  return { type: SET_GENRES, genres };
}
export function genreFetched(genre) {
  return { type: GENRE_FETCHED, genre };
}
export function genreSaved(genre) {
  // genre.songs = []; // Initialize with empty albums list
  return { type: GENRE_SAVED, genre };
}
export function genreUpdated(genre) {
  return { type: GENRE_UPDATED, genre };
}
export function genreDeleted(genreId) {
  return { type: GENRE_DELETED, genreId };
}
export function setGenreAlbums(genreId, albums) {
  return { type: SET_GENRE_ALBUMS, genreId, albums };
}

// -----------------------------------------------------------
// Business rules before dispatch
// -----------------------------------------------------------
export function fetchGenres() {
  return (dispatch) => {
    return axios
      .get("/api/genre", { headers: authHeader() })
      .then((response) => dispatch(setGenres(response.data.genres)))
      .catch((error) => console.log(error));
  };
}
export function fetchGenre(id) {
  return (dispatch) => {
    return axios
      .get(`/api/genre/${id}`, { headers: authHeader() })
      .then((response) => dispatch(genreFetched(response.data.genre)))
      .catch((error) => console.log(error));
  };
}
export function addGenre(genre) {
  return (dispatch) => {
    let { title, color } = genre;
    return axios
      .post("/api/genre", { title, color }, { headers: authHeader() })
      .then((response) => dispatch(genreSaved(response.data.genre)))
      .catch((error) => console.log(error));
  };
}
export function updateGenre(genre) {
  return (dispatch) => {
    let { id, title, songs, color } = genre;
    return axios
      .put(
        `/api/genre/${id}`,
        { id, title, songs, color },
        { headers: authHeader() }
      )
      .then((response) => dispatch(genreUpdated(response.data.genre)))
      .catch((error) => console.log(error));
  };
}
export function deleteGenre(genre) {
  return (dispatch) => {
    return axios
      .delete(`/api/genre/${genre.id}`, { headers: authHeader() })
      .then((response) => dispatch(genreDeleted(response.data.genre)))
      .catch((error) => console.log(error));
  };
}
export function setSongToCurrent(genre, song) {
  let { id: genreId } = genre;
  let { id: songId } = song;
  return (dispatch) => {
    return axios
      .post(
        `/api/genre/${genreId}/song/${songId}/current`,
        {},
        {
          headers: authHeader(),
        }
      )
      .then((response) => dispatch(genreSaved(response.data.genre)))
      .catch((error) => console.log(error));
  };
}

import React from "react";
import { Redirect } from "react-router-dom";

class HomePage extends React.Component {
  render() {
    return (
      <div>
        <Redirect to="/repertoires" />
        <ul>
          <li>Pozdrav, mi smo bend Zlatne Strune i najjaci smo na svijetu!</li>
        </ul>
      </div>
    );
  }
}

export default HomePage;
